<template>
  <div>
    <el-tabs v-model="tab" @tab-click="updateRoute">
      <el-tab-pane label="Menu" name="menu">
        <menus />
      </el-tab-pane>
      <el-tab-pane label="One-time Order Menu" name="one-time">
        <one-time-menu />
      </el-tab-pane>
      <el-tab-pane label="Meal Items" name="items">
        <meal-items />
      </el-tab-pane>
      <el-tab-pane label="Ingredients" name="ingredients">
        <ingredients />
      </el-tab-pane>
      <el-tab-pane label="Meal Categories" name="categories">
        <meal-categories />
      </el-tab-pane>
      <el-tab-pane label="Food Tags" name="food-tags">
        <FoodTags />
      </el-tab-pane>
      <el-tab-pane label="Menu Types" name="menu-types">
        <MenuTypes />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  name: "Meal",
  components: {
    Menus: () => import("@/components/Services/Meal/Menu/Menus"),
    MealItems: () => import("@/components/Services/Meal/MealItems/MealItems"),
    OneTimeMenu: () =>
      import("@/components/Services/Meal/MealOneTimeOrder/OneTimeMenu"),
    Ingredients: () =>
      import("@/components/Services/Meal/Ingredients/Ingredients"),
    MealCategories: () =>
      import("@/components/Services/Meal/MealCategories/MealCategories"),
    FoodTags: () => import("@/components/Services/Meal/FoodTags/FoodTags"),
    MenuTypes: () =>
      import("@/components/Services/Meal/MenuTypes/MenuTypeLayout"),
  },
  data() {
    return {
      tab: "menu",
    };
  },
  created() {
    let { tab } = this.$route.query;
    console.log(tab);
    this.tab = tab ? tab : "menu";
  },
  watch: {
    tab(v) {
      this.$router.replace({
        name: "services.service",
        service: "meal",
        query: { tab: v },
      });
    },
  },
  methods: {
    updateRoute(tab) {
      const currentTab = this.$route.query.tab;

      if (currentTab === this.tab) {
        return false;
      }
      this.$router.push({
        name: "services.service",
        service: "meal",
        query: { tab: tab.name },
      });
    },
  },
};
</script>
